import React from 'react'
import PropTypes from 'prop-types'

class Main extends React.Component {
  constructor(){
    super()
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  state = {
    contactname: "",
    contactemail: "",
    message: "",
    mailSent: false,
    error: null
  }
  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }
  handleSubmit = event => {
    event.preventDefault()
    const data = new FormData(event.target)
    fetch('https://ghouse.dev/api/',
    {
      method: "POST",
      body: data
    })
    .then(result => {
      this.setState({
        mailSent: result.data.sent
      })
      .catch(error => this.setState({
        error: error.message
      }))
    })
    
    
  }
  render() {

    let close = <div className="close" onClick={() => { this.props.onCloseArticle() }}></div>

    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}>

        <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{ display: 'none' }}>
          <h2 className="major">Contact</h2>
          <form onSubmit={this.handleSubmit}>
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="contactname" id="contactname" value={this.state.contactname} onChange={this.handleInputChange} />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="contactemail" id="contactemail" value={this.state.contactemail} onChange={this.handleInputChange} />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4" value={this.state.message} onChange={this.handleInputChange}></textarea>
            </div>
            <ul className="actions">
              <li><input type="submit" value="Send Message" className="special"/></li>
              <li><input type="reset" value="Reset" /></li>
            </ul>
          </form>
          {close}
        </article>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main