import React from 'react'
import PropTypes from 'prop-types'

const Header = (props) => (
    <header id="header" style={props.timeout ? { display: 'none' } : {}}>
        <div className="logo">
            <span className="icon fa-terminal"></span>
        </div>
        <div className="content">
            <div className="inner">
                <div className="typewriter-wrapper">
                    <div>
                        <h1 className="typewriter-text line-1">gHouse.dev()</h1>
                    </div>
                </div>
                <p>Modern web and mobile application development.</p>
            </div>
        </div>
        <nav>
            <ul>
                <li><a href="javascript:;" onClick={() => { props.onOpenArticle('contact') }}>Contact</a></li>
            </ul>
        </nav>
    </header>
)

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header
